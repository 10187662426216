
import { CameraStatus } from "@evercam/shared/types"

export default {
  name: "CameraStatusChip",
  props: {
    status: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    cameraStatuses() {
      return {
        [CameraStatus.Online]: {
          text: "Online",
          color: "success",
        },
        [CameraStatus.Offline]: {
          text: "Offline",
          color: "error",
        },
        [CameraStatus.OfflineScheduled]: {
          text: "Offline (Scheduled)",
          color: `${this.$vuetify.theme.dark ? "#b22a20" : "#f43f5e"}`,
        },
        [CameraStatus.Waiting]: {
          text: "Waiting",
          color: "",
        },
        [CameraStatus.UnderMaintenance]: {
          text: "Under Maintenance",
          color: `${this.$vuetify.theme.dark ? "#ffca28" : "#ffc107"}`,
        },
        [CameraStatus.WaitingForSiteVisit]: {
          text: "Waiting For Site Visit",
          color: `${this.$vuetify.theme.dark ? "#ffca28" : "#ffc107"}`,
        },
        [CameraStatus.Decommissioned]: {
          text: "Decommissioned",
          color: "",
        },
        [CameraStatus.OnHold]: {
          text: "On Hold",
          color: "",
        },
      }
    },
    currentStatus() {
      return this.cameraStatuses?.[this.status]
    },
  },
}
